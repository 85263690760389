import type React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GrindOlympiadsIndex from "./GrindOlympiadsIndex";
import GrindOlympiadsLayout from "./GrindOlympiadsLayout";
import LabsRoutes from "./LabsRoutes";
import AIMEPortal from "./components/AIMEPortal";
import AdminActionsPage from "./components/AdminActionsPage";
import AdminBlogPage from "./components/AdminBlogPage";
import ChallengeScoring from "./components/ChallengeScoring";
import DevBlog from "./components/DevBlog";
import ExamComponent from "./components/ExamComponent";
import FirebaseErrorDisplay from "./components/FirebaseErrorDisplay";
import Header from "./components/Header";
import KPMTPracticeNote from "./components/KPMTPracticeNote";
import NotificationBell from "./components/NotificationBell";
import PracticeNotes from "./components/PracticeNotes";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./components/Settings";
import Survey from "./components/Survey";
import UserMenu from "./components/UserMenu";
import UserProfile from "./components/UserProfile";
import UserResponseComponent from "./components/UserResponseComponent";
import Users from "./components/Users";
import { AuthProvider } from "./contexts/AuthContext";
import { useAuth } from "./contexts/AuthContext";

const AppContent: React.FC = () => {
  const { initError, retryInitialization } = useAuth();

  if (initError) {
    return (
      <FirebaseErrorDisplay error={initError} onRetry={retryInitialization} />
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          element={
            <GrindOlympiadsLayout
              Header={Header}
              NotificationBell={NotificationBell}
              UserMenu={UserMenu}
            />
          }
        >
          <Route index element={<GrindOlympiadsIndex />} />
          <Route path="challenge/:challengeId" element={<ExamComponent />} />
          <Route path="exam/:examId" element={<ExamComponent />} />
          <Route element={<ProtectedRoute adminOnly={false} />}>
            <Route
              path="exam/:examId/respond"
              element={<UserResponseComponent />}
            />
            <Route path="aime" element={<AIMEPortal />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route element={<ProtectedRoute adminOnly={true} />}>
            <Route path="admin/users" element={<Users />} />
            <Route path="admin/actions" element={<AdminActionsPage />} />
            <Route path="admin/blog" element={<AdminBlogPage />} />
            <Route
              path="admin/challenges/scoring"
              element={<ChallengeScoring />}
            />
          </Route>
          <Route path="labs/*" element={<LabsRoutes />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="blog" element={<DevBlog />} />
          <Route path="kpmt-practice-notes" element={<KPMTPracticeNote />} />
          <Route element={<ProtectedRoute adminOnly={false} />}>
            <Route path="practice-notes" element={<PracticeNotes />} />
          </Route>
          <Route path="survey" element={<Survey />} />
        </Route>
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
