import type React from "react";
import {
  type AIMEProblemDetails,
  type AMCProblemDetails,
  type OlympiadProblemDetails,
  type ProblemDetails,
  type ProblemReference,
  type ShortAnswerProblemDetails,
  isAIMEProblem,
  isAMCProblem,
  isOlympiadProblem,
  isShortAnswerProblem,
} from "../types";
import AIMEProblemView from "./AIMEProblemView";
import MultipleChoiceProblemView from "./MultipleChoiceProblemView";
import OlympiadProblemView from "./OlympiadProblemView";
import ShortTextAnswerProblemView from "./ShortTextAnswerProblemView";

interface ExamProblemRendererProps {
  problemRef: ProblemReference;
  examProblem?: { type: string };
  fullProblem: ProblemDetails & ProblemReference;
  onAnswer: (answer: string) => void;
  handleUpdateAnswer: (label: string, answer: string) => void;
  onSkip: () => void;
  selectedAnswer: string | null;
  responses: { answer: string; timestamp: number }[];
}

const ExamProblemRenderer: React.FC<ExamProblemRendererProps> = ({
  examProblem,
  fullProblem,
  onAnswer,
  handleUpdateAnswer,
  onSkip,
  selectedAnswer,
  responses,
}) => {
  // Return null if exam problem type doesn't match full problem type
  if (examProblem && examProblem.type !== fullProblem.type) {
    return null;
  }

  const commonProps = {
    onSubmit: onAnswer,
    onSkip,
    responses,
  };

  // Multiple choice problems (AMC)
  if (isAMCProblem(fullProblem)) {
    return (
      <MultipleChoiceProblemView
        problem={fullProblem}
        onOptionSelect={(answer) =>
          handleUpdateAnswer(fullProblem.label, answer)
        }
        selectedAnswer={selectedAnswer}
        onSubmit={onAnswer}
        {...commonProps}
      />
    );
  }

  // AIME problems (000-999 format)
  if (isAIMEProblem(fullProblem)) {
    return (
      <AIMEProblemView
        problem={fullProblem}
        onUpdateAnswer={onAnswer}
        currentAnswer={selectedAnswer || ""}
        {...commonProps}
      />
    );
  }

  // Short answer problems (including ARML)
  if (isShortAnswerProblem(fullProblem)) {
    return (
      <ShortTextAnswerProblemView
        problem={fullProblem}
        onUpdateAnswer={(answer) =>
          handleUpdateAnswer(fullProblem.label, answer)
        }
        currentAnswer={selectedAnswer || ""}
        {...commonProps}
      />
    );
  }

  // Essay problems (like ARML power)
  if (isOlympiadProblem(fullProblem)) {
    return (
      <OlympiadProblemView
        problem={fullProblem}
        onUpdateAnswer={onAnswer}
        currentAnswer={selectedAnswer || ""}
        {...commonProps}
      />
    );
  }

  // Return null if problem type is not recognized
  return null;
};

export default ExamProblemRenderer;
