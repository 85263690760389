import type React from "react";
import { useEffect, useRef, useState } from "react";
import type { ImageData } from "shared-types";
import type { AIMEProblemDetails, TimestampedResponse } from "../types";
import LatexRenderer from "./LatexRenderer";

interface AIMEProblemViewProps {
  problem: AIMEProblemDetails & { problemId: string; label: string };
  onUpdateAnswer: (answer: string) => void; // Update parent state while typing
  onSubmit: (answer: string) => void; // Submit final answer
  onSkip: () => void;
  autoSubmit?: boolean;
  currentAnswer: string;
  responses: TimestampedResponse[];
}

const AIMEProblemView: React.FC<AIMEProblemViewProps> = ({
  problem,
  onUpdateAnswer,
  onSubmit,
  onSkip,
  autoSubmit,
  currentAnswer,
  responses,
}) => {
  const imageData: ImageData | undefined = problem.image;
  // Separate digit inputs
  const [digits, setDigits] = useState(["", "", ""]);
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  // Update digits when currentAnswer changes
  useEffect(() => {
    if (currentAnswer) {
      const paddedAnswer = currentAnswer.padStart(3, "0");
      setDigits([paddedAnswer[0], paddedAnswer[1], paddedAnswer[2]]);
    }
  }, [currentAnswer]);

  const handleDigitChange = (index: number, value: string) => {
    // Only allow single digits
    if (!/^\d?$/.test(value)) return;

    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    // Auto-advance to next input if we entered a digit
    if (value && index < 2) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    switch (e.key) {
      case "Backspace":
        // If empty and backspace, go to previous input
        if (!digits[index] && index > 0) {
          inputRefs[index - 1].current?.focus();
        }
        break;
      case "ArrowLeft":
        // Move left if not at start
        if (index > 0) {
          inputRefs[index - 1].current?.focus();
        }
        break;
      case "ArrowRight":
        // Move right if not at end
        if (index < 2) {
          inputRefs[index + 1].current?.focus();
        }
        break;
      case "Enter": {
        // Submit if all digits are filled
        const answer = digits.join("");
        if (answer.length === 3) {
          onUpdateAnswer(answer);
        }
        break;
      }
      default:
        break;
    }
  };

  const _submitAnswer = (answer: string) => {
    onUpdateAnswer(answer);
    if (autoSubmit) {
      onSubmit(answer);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const answer = digits.join("");
    if (answer.length === 3) {
      onSubmit(answer);
    }
  };

  // Get visual state based on response history
  const getAnswerClass = () => {
    if (responses.length < 2) return "";

    const lastResponse = responses[responses.length - 1];
    const secondLastResponse = responses[responses.length - 2];

    if (lastResponse.answer === secondLastResponse.answer) {
      return "bg-green-100 border-green-500"; // Confirmed answer
    }
    return "bg-blue-100 border-blue-500"; // Latest answer
  };

  const inputIds = ["digit-0", "digit-1", "digit-2"];

  return (
    <div className="bg-white p-4 rounded shadow">
      <strong className="text-lg">Problem {problem.label}:</strong>
      <div className="mt-2">
        <LatexRenderer latex={problem.statement} />
      </div>
      {imageData && (
        <div className="mt-4 flex justify-center">
          <img
            src={imageData.url}
            alt={imageData.alt}
            width={imageData.width}
            height={imageData.height}
            className="max-w-full h-auto"
            loading="lazy"
          />
        </div>
      )}
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="mb-4">
          <label htmlFor={inputIds[0]} className="block font-medium mb-2">
            Enter your answer (000-999):
          </label>
          <div className="flex items-center space-x-2">
            {digits.map((digit, index) => (
              <div key={inputIds[index]}>
                <input
                  id={inputIds[index]}
                  ref={inputRefs[index]}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleDigitChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  className={`w-12 h-12 text-2xl text-center font-mono border rounded
                    focus:outline-none focus:ring-2 focus:ring-blue-500
                    ${getAnswerClass()}`}
                />
              </div>
            ))}
          </div>
          <p className="mt-2 text-sm text-gray-600">
            Type digits or use arrow keys to navigate. Enter to submit.
          </p>
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
              transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            disabled={digits.some((d) => d === "")}
          >
            Submit Answer
          </button>
          <button
            type="button"
            onClick={onSkip}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition-colors"
          >
            Skip
          </button>
        </div>
      </form>
    </div>
  );
};

export default AIMEProblemView;
